/* body {
    font-family: 'Arial', sans-serif;
    background-color: white;
    padding: 20px;
  } */
  
  .ShareButton__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
  }
  
  .ShareButton__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    margin-top: 3px;
    font-size: 12px;
  }
  
  .ShareButton__some-network__share-button {
    cursor: pointer;
  }
  
  .ShareButton__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
  }
  
  .ShareButton__some-network__custom-icon {
    width:32px;
    height:32px;
  }